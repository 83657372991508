import React, { useEffect, useState } from "react";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import InputIterator from "../../components/inputIterator";
import AddButtonSection from "../../components/AddButtonSection";
import { DevTool } from "@hookform/devtools";
import { useForm } from "react-hook-form";
import { addPayment } from "../../utils/serviceData";
import { useToast } from "../../commonComponents/toast";
import Loader from "../../commonComponents/loader";
import { commonApi } from "../../utils/apiService";
import moment from "moment";
export default function AddPayments({ patientId, invoice, ...props }) {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverLoading, setServerLoading] = useState(null);
  const [renderInput, setRenderInput] = useState({ formRender: addPayment });
  const { showToast } = useToast();

  const init = async (formRender) => {
    setRenderInput((prev) => ({
      formRender: formRender ?? prev?.formRender,
      formName: "editProfile",
      buttons: addVisitsButton,
      header: <div className="sideBar-header">Add Payment</div>,
    }));
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (serverLoading) return;
    setServerLoading(true);
    const payload = {
      collectable_payment_description_id:
        data?.collectable_payment_description_id,
      payment_date: moment(data?.payment_date).format("YYYY-MM-DD"),
      amount_paid: data?.amount_paid,
    };
    commonApi("/api/addPayment", payload)
      .then((data) => {
        if (data?.data?.status == "created") {
          reset();
          showToast("success", "Payment Added Successfully");
          props?.init?.();
          props?.setVisible?.(false);
          setServerLoading(false);
        } else {
          showToast("error", "Something went wrong");
          setServerLoading(false);
        }
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setServerLoading(false);
      });
  };

  const addVisitsButton = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        reset();
        props?.setVisible?.(false);
      },
    },
  ];

  useEffect(() => {
    if (invoice) {
      setLoading(true);
      setRenderInput((prev) => ({
        ...prev,
        formRender: addPayment.map((data) => {
          if (data?.name === "collectable_payment_description_id") {
            return {
              ...data,
              options: [
                {
                  label: invoice?.collectable_payment_description_id,
                  value: invoice?.collectable_payment_description_id,
                },
              ],
            };
          }else if(data?.name === "amount_paid"){
            return {
              ...data,
              rules:{
                ...data,
                validate: {
                  minValue: (value) =>
                    parseInt(value, 10) > 0 || "Payment amount cannot be zero",
                  maxValue: (value) =>
                    parseInt(value, 10) <= watch("total_balance_amount") || `Payment amount should not exceed ${watch("total_balance_amount")}`
                },
              }
            }
          } else {
            return data;
          }
        }),
      }));

      setValue("payment_date", new Date());
      [
        "collectable_payment_description_id",
        "total_collectable_amount",
        "total_paid_amount",
        "total_balance_amount",
      ].map((item) => {
        setValue(item, invoice[item]);
      });
      init();
    }
  }, [invoice]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  return (
    <React.Fragment>
      <PrimarySideBar
        visible={visible}
        onHide={() => {
          reset();
          props?.setVisible?.(false);
        }}
        header={renderInput?.header}
      >
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {renderInput?.buttons ? (
              <React.Fragment>
                <InputIterator
                  control={control}
                  errors={errors}
                  formRender={renderInput?.formRender}
                />

                <div className="pt-3">
                  <AddButtonSection
                    disabled={serverLoading}
                    buttons={renderInput?.buttons}
                  />
                </div>
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </React.Fragment>
        )}
      </PrimarySideBar>
      <DevTool control={control} />
    </React.Fragment>
  );
}
