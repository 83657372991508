import { DevTool } from "@hookform/devtools";
import React, { useEffect, useRef, useState } from "react";
import AddOrEditPatient from "../patients/addOrEditPatient";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import Search from "../../components/Search";
import ProfileView from "../../components/profileView";
import { useToast } from "../../commonComponents/toast";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import ButtonWithSearch from "../../components/buttonWithSearch";
import { commonApi, commonGetApi } from "../../utils/apiService";
import {
  arrageArrayFunc,
  convetToSearchField,
  dataToNameAndLabel,
  fetchOptions,
} from "../../utils/utils";
import StandardCard from "../../components/standardCard";
import SecoundaryCard from "../../components/secoundaryCard";
import PrimaryHeading from "../../components/primaryHeading";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import InputIterator from "../../components/inputIterator";
import AddButtonSection from "../../components/AddButtonSection";
import CommonModal from "../../components/commonModel";
import { userFormRender,assignRoleFormRender} from "../../utils/serviceData";
export default function Users() {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
  } = useForm({});
  const { showToast } = useToast();
  const menuLeft = useRef(null);
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState([]);
  const [user, setUser] = useState({});
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [examination, setExamination] = useState({});
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [editUserVisible, setEditUserVisible] = useState(false);
  const [assignRoleVisible, setAssignRoleVisible] = useState(false);
  const [visible, setVisible] = useState({});
  const [editUserDetails, setEditUserDetails] = useState({});
  const searchFields = ["user_name", "first_name", "last_name"];
  const [formRender, setFormRender] = useState(userFormRender);
  const [assignRoleformRender, setAssignRoleFormRender] = useState(assignRoleFormRender);
  const [navigator, setNavigator] = useState({
    page: 1,
    first: 0,
    rows: 10,
    totalRecords: 1,
    sortBy: "user_name",
    sortDir: "asc",
    searchCriteria: convetToSearchField(searchFields, ""),
  });
  const fields = [
    "user_name",
    "first_name",
    "last_name",
    "email",
    "address",
    "city",
    "district",
    "state",
    "user_type",
    "pincode",
    "phone_no",
    "password",
  ];

  function onPageChange(event) {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      first: event?.first,
      page: event.page + 1,
    }));
    init({
      ...navigator,
      first: event?.first,
      page: event.page + 1,
    });
  }

  const init = async (navigator) => {
    setInitLoading(true);
    let currentObj = {
      query: {
        page: navigator?.page,
        limit: navigator?.rows,
        searchCriteria: navigator?.searchCriteria,
      },
    };

    Promise.all([commonApi("/api/userdetails", currentObj)])
      .then(([data]) => {
        const userData = data?.data?.data;

        setNavigator((prev) => ({
          ...prev,
          totalRecords: data?.data?.total,
          page: data?.data?.currentPage,
        }));

        setProducts(
          arrageArrayFunc(dataToNameAndLabel(userData, null, null), [
            "user_name",
            "email",
            "phone_no",
            "address",
          ])
        );
        setValues(userData);
        setLoading(false);
        setInitLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSortClick = (event) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    }));
    init({
      ...navigator,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    });
  };

  const onSubmit = async (data) => {
    if (loader) return;
    setLoader(true);
    let headers = {
      "Content-Type": "application/json",
    };
    await commonApi("api/adduserdetails", data, {
      headers,
    })
      .then((data) => {
        showToast("success", "Added user Successfully");
        setAddUserVisible(false);
        init(navigator);
        reset();
        setLoader(false);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setLoader(false);
      });
  };

  const onEdit = async (data) => {
    if (loader) return;
    setLoader(true);
    let headers = {
      "Content-Type": "application/json",
    };
    await commonApi(
      "api/edituserdetails",
      { ...data, user_id: editUserDetails?.user_id },
      {
        headers,
      }
    )
      .then((data) => {
        showToast("success", "Updated user Successfully");
        setEditUserVisible(false);
        init(navigator);
        reset();
        setLoader(false);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setLoader(false);
      });
  };

  const onDelete = async () => {
    let headers = {
      "Content-Type": "application/json",
    };
    await commonApi(
      "api/deleteuserdetails",
      { user_id: visible?.id },
      {
        headers,
      }
    )
      .then((data) => {
        if (data?.status == 200) {
          showToast("success", "Deleted user Successfully");
          init(navigator);
          setVisible((prev) => ({
            ...prev,
            show: false,
          }));
        }
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
      });
  };

  const button = [
    {
      type: "primarySuccessButton",
      label: "Add User",
      icon: "pi pi-plus",
      class: "mt-2 w-full flex pr-3",
      onClick: () => {
        setAddUserVisible(true);
        setEditUserDetails({});
      },
    },
  ];
  const itemRenderer = (item, props) => (
    <div className="p-menuitem-content">
      {item.label === "Assign Role" && (
        <Button
          className="card-button-assign-role"
          label="Assign Role"
          onClick={() => {
            setAssignRoleVisible(true)
          }}
        />
      )}
      {item.label === "Edit" && (
        <Button
          className="card-button-edit-secound"
          label="Edit"
          onClick={() => {
            setEditUserVisible(true);
            setEditUserDetails(props?.props?.itemProp);
          }}
        />
      )}
      {item.label === "Delete" && (
        <Button
          className="card-button-delete"
          label="Delete"
          onClick={() => {
            setVisible({
              id: props?.props?.itemProp?.user_id,
              show: true,
            });
          }}
        />
      )}
    </div>
  );

  const AssignRolebuttons = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      disabled: Object.keys(dirtyFields).length === 0 || loader,
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        setAssignRoleVisible(false);
      },
    },
  ];

  const AddUserbuttons = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      disabled: Object.keys(dirtyFields).length === 0 || loader,
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        setAddUserVisible(false);
      },
    },
  ];
  const EditUserbuttons = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      disabled: Object.keys(dirtyFields).length === 0 || loader,
      onClick: handleSubmit(onEdit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        setEditUserVisible(false);
      },
    },
  ];

  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Yes",
      class: "w-8rem pr-3",
      onClick: () => {
        onDelete();
      },
    },
    {
      type: "secondaryButton",
      label: "No",
      class: "w-7rem",
      onClick: () => {
        setVisible((prev) => ({
          ...prev,
          show: false,
        }));
      },
    },
  ];

  function handleSearchChange(value) {
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: convetToSearchField(searchFields, value),
    }));
    init({
      ...navigator,
      searchCriteria: convetToSearchField(searchFields, value),
    });
  }
  const initiateForm = () => {
    fetchOptions("User_TYpe", (userType) =>
      setFormRender((prev) =>
        prev.map((item) => {
          if (item?.name == "user_type") return { ...item, options: userType };
          else return item;
        })
      )
    );
  };

  useEffect(() => {
    initiateForm();
    init(navigator);
  }, []);

  useEffect(() => {
    fields.forEach((fieldName) => {
      setValue(fieldName, editUserDetails?.[fieldName]);
    });
  }, [editUserDetails]);

  return (
    <div className="">
      <PrimaryHeading className={"mt-5 mb-3"} />
      <DataGrid
        header={
          <ButtonWithSearch
            buttons={button}
            searchHeaderLable={"Users"}
            searchPlaceHolder={"Search Users"}
            isSearchEnabled={true}
            isCalenderEnabled={false}
            isButtonEnabledLeft={false}
            isButtonEnabledRight={true}
            loading={initLoading}
            styles={{ gridTemplateColumns: "1fr 400px" }}
            onChange={handleSearchChange}
          />
        }
        onSortClick={onSortClick}
        onPageChange={onPageChange}
        isPaginatorEnabled={true}
        navigator={navigator}
        column={products}
        value={values}
        ref={menuLeft}
        loading={loading}
        optionsView={[
          {
            items: [
              {
                label: "Assign Role",
                icon: "pi pi-pencil",
                template: itemRenderer,
              },
              {
                label: "Edit",
                icon: "pi pi-pencil",
                template: itemRenderer,
              },
              {
                label: "Delete",
                icon: "pi pi-upload",
                template: itemRenderer,
              },
            ],
          },
        ]}
      />
      {addUserVisible && (
        <PrimarySideBar
          visible={addUserVisible}
          onHide={() => setAddUserVisible(false)}
          header={<div className="sideBar-header">Add User</div>}
        >
          <InputIterator
            control={control}
            errors={errors}
            formRender={formRender}
          />
          <div className="pt-3">
            <AddButtonSection buttons={AddUserbuttons} />
          </div>
        </PrimarySideBar>
      )}
      {editUserVisible && (
        <PrimarySideBar
          visible={editUserVisible}
          onHide={() => setEditUserVisible(false)}
          header={<div className="sideBar-header">Edit User</div>}
        >
          <InputIterator
            control={control}
            errors={errors}
            formRender={formRender}
          />
          <div className="pt-3">
            <AddButtonSection buttons={EditUserbuttons} />
          </div>
        </PrimarySideBar>
      )}
      {assignRoleVisible && (
        <PrimarySideBar
          visible={assignRoleVisible}
          onHide={() => setAssignRoleVisible(false)}
          header={<div className="sideBar-header">Assign Role</div>}
        >
          <InputIterator
            control={control}
            errors={errors}
            formRender={assignRoleformRender}
          />
          <div className="pt-3">
            <AddButtonSection buttons={AssignRolebuttons} />
          </div>
        </PrimarySideBar>
      )}
      <CommonModal
        visible={visible?.show}
        header="User Patient"
        onHide={() => setVisible(false)}
      >
        <div>
          <div className="flex justify-content-center pr-4">
            Would you like to delete this user?
          </div>

          <div className="mt-6 ml-2">
            <AddButtonSection buttons={submitButtons} />
          </div>
        </div>
      </CommonModal>
      <DevTool control={control} />
    </div>
  );
}
