import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import loginImage from "../../assets/images/login-image.png";
import logo from "../../assets/images/logo.svg";
import leftIcon from "../../assets/images/chevron-left.svg";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import InputComponent from "../../commonComponents/inputComponent";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { Controller } from "react-hook-form";
import { login, getCampDetails } from "../../utils/apiService";
import { useToast } from "../../commonComponents/toast";
import InputPrimaryComponents from "../../components/inputPrimaryComponents";
import Loader from "../../commonComponents/loader";
import { Dialog } from "primereact/dialog";
const Login = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [checked, setChecked] = useState(false);
  const [isLoadedPages, setLoadedPages] = useState("Login");
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const apiCall = async (payload) => {
    setLoading(true);
    await login(payload).then((res) => {
      setLoading(false);
      reset();
    });
  };
  const getCampCall = async () => {
    await getCampDetails().then((res) => {
      let campDetails = res.data?.map((item) => ({
        label: item?.description,
        value: item?.camp_details_id,
      }));
      setCampaigns(campDetails);
    });
  };
  const handleLogin = async (data) => {
    apiCall({
      email: data.username,
      password: data.password,
    });
  };
  const handleResetLink = async (data) => {
    reset();
  };
  const handleUpdatePassword = async (data) => {
    reset();
  };
  const redirectPage = async (data) => {
    setLoadedPages(data);
    reset();
  };
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    getCampCall();
  }, []);

  return (
    <div className="Login__main-container">
      <div className="Login__left-section">
        <div className="Login__image-sec">
          <img src={loginImage} alt="Login Image" />
        </div>
      </div>
      <div className="Login__right-section">
        <div className="Login__right-log-sec">
          <img src={logo} alt="" />
          <h1>Rotary Club</h1>
        </div>
        <div className="Login__right-form-sec">
          {isLoadedPages === "Login" && (
            <React.Fragment>
              <div className="Login__login-sec">
                <div className="Login__right-header-sec">
                  <p className="heading">Welcome to Rotary Club</p>
                  <p className="sub-heading">
                    Please sign-in to your account to begin
                  </p>
                </div>
                {/* <form> */}
                <div className="Login__form-container">
                  <div className="Login__form-control">
                    <InputPrimaryComponents
                      key={0}
                      type={"text"}
                      label={"Email or Username"}
                      errors={errors}
                      control={control}
                      name={"username"}
                      rules={{ required: "Username is required" }}
                      paddingStyle={true}
                    />
                  </div>
                  <div className="Login__form-control">
                    <InputPrimaryComponents
                      key={1}
                      type={"password"}
                      label={"Password"}
                      errors={errors}
                      control={control}
                      name={"password"}
                      rules={{ required: "Password is required" }}
                      paddingStyle={true}
                    />
                  </div>
                  <div className="Login__form-control">
                    <InputPrimaryComponents
                      key={2}
                      type={"dropdown"}
                      control={control}
                      errors={errors}
                      name={"campaign"}
                      label={"Camp"}
                      rules={{ required: "Select campaign" }}
                      options={campaigns}
                      paddingStyle={true}
                    />
                  </div>
                  <div className="Login__remember-sec">
                    <div className="remember-left-sec">
                      <Checkbox
                        inputId="remember"
                        onChange={(e) => setChecked(e.checked)}
                        checked={checked}
                      ></Checkbox>
                      <label htmlFor="remember" className="remember-label">
                        Remember Me
                      </label>
                    </div>
                    <div className="remember-right-sec">
                      <Button
                        link
                        onClick={() => {
                          // setLoadedPages('ForgotPassword')
                          // navigate("/forgotpassword")
                          setVisible(true);
                        }}
                      >
                        <div className="button-actions">
                          <React.Fragment>
                            <span
                              class="p-button-label p-c"
                              data-pc-section="label"
                            >
                              Forgot Password?
                            </span>
                          </React.Fragment>
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div className="Login__login-btn">
                    <Button
                      onClick={handleSubmit(handleLogin)}
                      disabled={loading}
                    >
                      <div className="button-actions">
                        {loading ? (
                          <React.Fragment>
                            <Loader />
                            <span
                              class="p-button-label p-c"
                              data-pc-section="label"
                            >
                              Processing
                            </span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span
                              class="p-button-label p-c"
                              data-pc-section="label"
                            >
                              Login
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </Button>
                  </div>
                  <div className="Login__contact-admin-sec">
                    <span className="contact-not-registered">
                      Not registered?
                    </span>{" "}
                    <Button
                      link
                      label="Contact an administrator"
                      onClick={() => setVisible(true)}
                    />
                  </div>
                </div>
                {/* </form> */}
              </div>
            </React.Fragment>
          )}
          {isLoadedPages === "ForgotPassword" && (
            <React.Fragment>
              <div className="Login__forgot-pwd-sec">
                <div className="Login__right-header-sec">
                  <p className="heading">Forgot Password</p>
                  <p className="sub-heading">
                    Enter your email and we'll send you instructions to reset
                    your password
                  </p>
                </div>
                <form>
                  <div className="Login__form-container">
                    <div className="Login__form-control">
                      <InputPrimaryComponents
                        key={0}
                        type={"text"}
                        label={"Email or Username"}
                        errors={errors}
                        control={control}
                        name={"username"}
                        rules={{ required: "Username is required" }}
                        required={true}
                      />
                    </div>
                    <div className="Login__login-btn">
                      <Button onClick={handleSubmit(handleResetLink)}>
                        <div className="button-actions">
                          {loading ? (
                            <React.Fragment>
                              <Loader />
                              <span
                                class="p-button-label p-c"
                                data-pc-section="label"
                              >
                                Processing
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span
                                class="p-button-label p-c"
                                data-pc-section="label"
                              >
                                Send Reset Link
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      </Button>
                    </div>
                    <div className="Login__contact-admin-sec back-to-login">
                      <span className="btn-left-icon">
                        <img src={leftIcon} alt="" />
                      </span>
                      <Button
                        link
                        label="Back to login"
                        onClick={() => redirectPage("login")}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          )}
          {isLoadedPages === "Reset" && (
            <React.Fragment>
              <div className="Login__reset-pwd-sec">
                <div className="Login__right-header-sec">
                  <p className="heading">Reset Password</p>
                  <p className="sub-heading">
                    Your new password must be different from previously used
                    passwords
                  </p>
                </div>
                <form>
                  <div className="Login__form-container">
                    <div className="Login__form-control">
                      <InputPrimaryComponents
                        key={0}
                        type={"password"}
                        label={"Password"}
                        errors={errors}
                        control={control}
                        name={"password"}
                        rules={{ required: "Password is required" }}
                        required={true}
                      />
                    </div>
                    <div className="Login__form-control">
                      <InputPrimaryComponents
                        key={1}
                        type={"password"}
                        label={"Confirm Password"}
                        errors={errors}
                        control={control}
                        name={"confirmPassword"}
                        rules={{ required: "Confirm Password is required" }}
                        required={true}
                      />
                    </div>
                    <div className="Login__login-btn">
                      <Button onClick={handleSubmit(handleUpdatePassword)}>
                        <div className="button-actions">
                          {loading ? (
                            <React.Fragment>
                              <Loader />
                              <span
                                class="p-button-label p-c"
                                data-pc-section="label"
                              >
                                Processing
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span
                                class="p-button-label p-c"
                                data-pc-section="label"
                              >
                                Set new Password
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      </Button>
                    </div>
                    <div className="Login__contact-admin-sec back-to-login">
                      <span className="btn-left-icon">
                        <img src={leftIcon} alt="" />
                      </span>
                      <Button
                        link
                        label="Back to login"
                        onClick={() => redirectPage("Login")}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        model
        style={{ width: "40rem" }}
        header={<span>Information</span>}
        footer={
          <div>
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => setVisible(false)}
              autoFocus
            />
          </div>
        }
      >
        Kindly contact the Administrator
      </Dialog>
      <DevTool control={control} />
    </div>
  );
};

export default Login;
