import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";

const Context = React.createContext();

export default function ToastComponent({ children }) {
  const toast = useRef(null);
  const [toastProp, setToastProp] = React.useState({
    position: "top-right",
  });

  const toastTypes = (type, message, life) => {
    switch (type) {
      case "success":
        return {
          severity: "success",
          summary: "Success",
          detail: message,
          life: life,
        };

      case "warn":
        return {
          severity: "warn",
          summary: "Warn",
          detail: message,
          life: life,
        };
      case "info":
        return {
          severity: "info",
          summary: "Info",
          detail: message,
          life: life,
        };

      case "error":
        return {
          severity: "error",
          summary: "Error",
          detail: message,
          life: life,
        };

      default:
        return {
          severity: "success",
          summary: "Success",
          detail: message,
          life: life,
        };
    }
  };

  const showToast = (type = "success", message = "", life = 3000) => {
    toast?.current?.show(toastTypes(type, message, life));
  };

  return (
    <Context.Provider value={{ showToast, setToastProp }}>
      <Toast ref={toast} position={toastProp?.position} />
      {children}
    </Context.Provider>
  );
}

export function useToast() {
  return useContext(Context);
}
