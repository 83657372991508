import React, { useState, useEffect } from "react";
import moment from "moment";
import { renderInputHeader } from "../../utils/utils";
import { useForm } from "react-hook-form";
import AddButtonSection from "../../components/AddButtonSection";
import { commonApi } from "../../utils/apiService";
import { dateFormat ,commonData,addLookUpOption,fetchOptions} from "../../utils/utils";
import { useToast } from "../../commonComponents/toast";
import { DevTool } from "@hookform/devtools";
export default function SurgeryRecommendation({ patientVisitId }) {
  const {
    control,
    formState: { errors, dirtyFields },
    setValue,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm();
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surgeryType, setSurgeryType] = useState([]);
  const lookupTypes = [
    { type: "Surgery_Type", setter: setSurgeryType },
  ];
  let headers = {
    "Content-Type": "application/json",
  };
  const fields = [
    "eye_detail",
    "treatment_recommended",
    "date_of_transportation",
    "other_comments",
  ];
  const formData = [
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          // header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "dropdown",
              name: "eye_detail",
              isShowField: true,
              label: "Eye Detail",
              fieldLabel: "eye_detail",
              placeholder: "Type here",
              options: [
                {
                  label: "Right Eye",
                  value: "Right Eye",
                },
                {
                  label: "Left Eye",
                  value: "Left Eye",
                },
              ],
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          customClass: "py-3",
          formRender: [
            {
              type: "dropdown-add",
              name: "treatment_recommended",
              label: "Treatment Recommended",
              fieldLabel: "treatment_recommended",
               placeholder: "Select",
              value: commonData(surgeryType, watch("_treatment_recommended")),
              inputParentClass: "w-full pr-3",
              options: surgeryType,
              nestedInput: { ...register("_treatment_recommended") },
              nestedOnClick: (a, b) => {
                if (dirtyFields?._treatment_recommended) {
                  addLookUpOption(
                    {
                      lookup_type_name: "Surgery_Type",
                      name: watch("_treatment_recommended"),
                    },
                    () => {
                      fetchOptions(
                        "Surgery_Type",
                        setSurgeryType,
                        showToast
                      );
                    },
                    showToast
                  );
                  setValue("_treatment_recommended", "");
                }
              },
            },
          ],
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          customClass: "py-3",
          formRender: [
            {
              type: "calendar",
              name: "date_of_transportation",
              minDate: new Date(),
              label: "Date of Transportation",
              fieldLabel: "date_of_transportation",
              placeholder: "Select",
              inputParentClass: "w-full pr-3 div_pb-0",
            },
          ],
        },
        {
          parentType: "plain",
          type: "input-group",
          customClass: "py-3 p-0",
          formRender: [
            {
              type: "text",
              name: "other_comments",
              label: "Other",
              placeholder: "Type here",
              fieldLabel: "other_comments",
              inputParentClass: "w-full pr-3",
            },
          ],
        },
      ],
    },
  ];
 
  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    let surgeryRecommend = {
      ...data,
      date_of_transportation: dateFormat(data["date_of_transportation"]),
      patient_visit_id: patientVisitId,
    };
    let headers = {
      "Content-Type": "application/json",
    };
    const action = edit
      ? "editSurgeryRecommendedDetails"
      : "addSurgeryRecommendedDetails";
    await commonApi(`api/${action}`, surgeryRecommend, {
      headers,
    })
      .then((data) => {
        if (data?.data) {
          showToast(
            "success",
            `${edit ? "Updated" : "Added"} Surgery Recommended`
          );
          getSurgeryRecommendation();
          setLoading(false);
        }
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setLoading(false);
      });
  };

  const discard = () => {
    fields.forEach((field) => {
      setValue(field, "");
    });
  };

  useEffect(() => {
    getSurgeryRecommendation();
  }, [patientVisitId]);
  useEffect(() => {
    lookupTypes.forEach(({ type, setter }) => {
      fetchOptions(type, setter);
    });
  }, []);

  const getSurgeryRecommendation = async () => {
    const data = { patient_visit_id: patientVisitId };
    try {
      const response = await commonApi(
        "api/searchSurgeryRecommendedDetails",
        data,
        { headers }
      );
      const formData = response?.data?.data || null;
      if (Object.keys(formData).length !== 0) {
        setEdit(true);
        fields.forEach((field) => {
          if (field === "date_of_transportation") {
            let value = formData
              ? moment(formData?.[field], "YYYY-MM-DD")?._d
              : "";
            setValue(field, value || "");
          } else {
            let value = formData ? formData[field] : "";
            setValue(field, value || "");
          }
        });
      } else if (Object.keys(formData).length === 0) {
        setEdit(false);
        discard();
      }
    } catch (err) {
      showToast("error", "Something went wrong");
    }
  };

  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        discard();
      },
    },
  ];

  return (
    <div>
      <div>
        {formData?.map((item, index) => {
          return renderInputHeader(
            item,
            index,
            setValue,
            watch,
            control,
            errors
          );
        })}
      </div>
      <div className="ml-2">
        <AddButtonSection disabled={Object.keys(dirtyFields).length === 0 || loading} buttons={submitButtons} />
      </div>
    </div>
  );
}
