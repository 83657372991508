import React, { useState, useEffect } from "react";
import Search from "../../components/Search";
import "../../assets/styles/Patients.scss";
import UsersImg from "../../assets/images/users.svg";
import User2Img from "../../assets/images/user-2.svg";
import User3Img from "../../assets/images/user-3.svg";
import User4Img from "../../assets/images/user-4.svg";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import AddButtonSection from "../../components/AddButtonSection";
import DataGrid from "../../commonComponents/dataGrid/dataGrid";
import Util, {
  convetToSearchField,
  dataToNameAndLabel,
  generateValidObject,
  dateFormat,
  orderObj,
  mask,
} from "../../utils/utils";
import DataGridHeader from "../../commonComponents/dataGrid/dataGridAddPatientHeader";
import {
  patientCreateOrUpdate,
  patientList,
  commonGetApi,
  commonApi,
} from "../../utils/apiService";
import {
  patientFormRender as formRender,
  // addVisitsRender as visitFormRender,
  nonRequirePatientListField,
} from "../../utils/serviceData";
import { useToast } from "../../commonComponents/toast";
import InputIterator from "../../components/inputIterator";
import CapturePhoto from "../../components/capturePhoto/capturePhoto";
import moment from "moment";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import AddVisits from "../visits/addVisits";
import AddOrEditPatient from "./addOrEditPatient";
import DeletePatient from "./deletePatient";
import SelectOption from "../../components/selectButton";
import calenderImg from "../../assets/images/calendar-time.svg";
import calenderWhite from "../../assets/images/calendar-white.svg";
import { Dialog } from "primereact/dialog";
// import { Calendar } from "primereact/calendar";
import { DateRangePicker } from "react-date-range";
import { Calendar } from "react-date-range";
import DateRange from "../../components/dateRange";
import DateRangePopUp from "../../components/dateRangePopUp";
import DateSelect from "../../components/dateSelect";
const Patients = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({});
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [optionalVisible, setOptionalVisible] = useState({
    addVisit: false,
    editPatient: false,
  });
  const searchFields = ["patient_name", "first_name", "patient_code"];
  const [loading, setLoading] = useState(true);
  const [uploadedImg, setUploadedImg] = useState({});
  const [dashboard, setDashboard] = useState([]);
  const [show, setShow] = useState(false);
  const [serverLoading, setServerLoading] = useState(false);
  const [selectOption, setSelectOption] = useState(1);
  const [navigator, setNavigator] = useState({
    page: 1,
    first: 0,
    rows: 10,
    totalRecords: 1,
    sortBy: "patient_name",
    sortDir: "asc",
    searchCriteria: convetToSearchField(searchFields, ""),
  });
  const init = async (navigator) => {
    let currentObj = {
      query: {
        page: navigator?.page,
        limit: navigator?.rows,
        sortFields: [[navigator?.sortBy, navigator?.sortDir]],
        searchCriteria: navigator?.searchCriteria,
      },
    };

    await patientList(currentObj)
      .then((data = []) => {
        if (data?.data?.data) {
          const finalData = data?.data?.data;
          const updatedPatient = finalData.map((p) => ({
            ...p, // Keep other properties unchanged
            aadhar_no: mask(p.aadhar_no),
          }));
          setNavigator((prev) => ({
            ...prev,
            totalRecords: data?.data?.total,
            page: data?.data?.currentPage,
          }));
          setProducts(
            orderObj(
              [
                "patient_name",
                "patient_code",
                "aadhar_no",
                "gender",
                "dob",
                "phone_no",
              ],
              dataToNameAndLabel(
                finalData,
                null,
                null,
                nonRequirePatientListField,
                null,
                { patient_name: "patient", patient_code: "patient_id", aadhar_no: "aadhaar_no" }
              )
            )
          );
          setValues(updatedPatient);
          setLoading(false);
        } else {
          setNavigator((prev) => ({
            ...prev,
            totalRecords: 1,
            page: 1,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const patientDashboard = async (startDate, endDate) => {
    await commonApi("api/patientdashboard", {
      startDate: startDate,
      endDate: endDate,
    })
      .then((response) => {
        setDashboard(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dashoardDetails = (counts) => {
    const patient = dashboard?.find((item) => item?.status === counts);
    return patient?.count || 0;
  };
  const itemRenderer = (item, props) => (
    <div className="p-menuitem-content">
      {item.label === "Add visit" && (
        <Button
          className="card-button-add"
          icon="pi pi-plus"
          label="Add Visit"
          onClick={() =>
            setOptionalVisible((prev) => ({
              ...prev,
              addVisit: true,
              selectedPatient: props?.props?.itemProp?.patient_id,
            }))
          }
        />
      )}
      {item.label === "Edit" && (
        <Button
          className="card-button-edit-secound"
          label="Edit"
          onClick={() => {
            setOptionalVisible((prev) => ({
              ...prev,
              editPatient: true,
              selectedPatient: props?.props?.itemProp?.patient_id,
            }));
          }}
        />
      )}
      {/* {item.label === "Remove" && (
        <Button
          className="card-button-delete"
          label="Delete"
          onClick={() => {
            setOptionalVisible((prev) => ({
              ...prev,
              deletePatient: true,
              selectedPatient: props?.props?.itemProp,
            }));
          }}
        />
      )} */}
    </div>
  );

  const onSubmit = async (data) => {
    if (serverLoading) return;
    setServerLoading(true);
    let validObj = generateValidObject(data);
    const dob = dateFormat(validObj["dob"]);
    validObj = {
      ...validObj,
      dob: dob,
    };
    let finalData = validObj;
    let headers;
    if (uploadedImg && uploadedImg.size && uploadedImg.size > 0) {
      finalData = new FormData();
      finalData.append("profile_picture", uploadedImg);
      finalData.append("_payload", JSON.stringify(validObj));
      headers = {
        "Content-Type": "multipart/form-data",
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    await patientCreateOrUpdate("api/addpatientdetails", finalData, {
      headers,
    })
      .then((data) => {
        showToast("success", "Patient Added Successfully");
        setVisible(false);
        init(navigator);
        setUploadedImg({});
        reset();
        setServerLoading(false);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setServerLoading(false);
      });
  };

  const onImageCapture = (param) => {
    let capturedFile = Util.dataURLtoFile(
      param,
      `image-capture${moment().format("YYYYMMDDHHmmssa")}.jpg`
    );
    setUploadedImg(capturedFile);
  };

  useEffect(() => {
    init(navigator);
  }, []);

  const buttons = [
    {
      spType: "file",
      type: "primaryButton",
      label: "Upload Profile Picture",
      icon: "pi pi-upload",
      class: "col-9",
      info: uploadedImg?.name,
      onClick: (e) => {
        e?.target?.files?.[0] && setUploadedImg(e?.target?.files?.[0]);
      },
    },
    {
      type: "secondaryButton",
      label: "Reset",
      class: "col-3",
      onClick: () => {
        setUploadedImg(null);
      },
    },
    {
      type: "render",
      render: (
        <div className="msgText font-normal text-sm p-2 pb-3">
          Allowed JPG, GIF or PNG. Max size of 800K
        </div>
      ),
    },
    {
      type: "render",
      render: (
        <CapturePhoto
          imgWidth={200}
          imgHeight={150}
          webcamWidth={410}
          webcamHeight={320}
          onImageCapture={onImageCapture}
        />
      ),
    },
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        setVisible(false);
      },
    },
  ];

  function handleClick(event) {
    if (event === "addPatient") setVisible((prev) => !prev);
  }

  function onPageChange(event) {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      first: event?.first,
      page: event.page + 1,
    }));
    init({
      ...navigator,
      first: event?.first,
      page: event.page + 1,
    });
  }

  const onRowCountChange = (e) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      rows: e?.target?.value,
      page: 1,
      first: 0,
    }));
    init({
      ...navigator,
      page: 1,
      first: 0,
      rows: e?.target?.value,
    });
  };

  const onSortClick = (event) => {
    setLoading(true);
    setNavigator((prev) => ({
      ...prev,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    }));
    init({
      ...navigator,
      sortBy: event?.sortField,
      sortDir: event?.sortOrder == 1 ? "asc" : "desc",
      sortFields: [[event?.sortField, event?.sortOrder == 1 ? "ASC" : "DESC"]],
    });
  };

  const onRowClick = (event) => {
    navigate(`/visits/${event?.data?.patient_id}`);
  };

  function handleSeachChange(value) {
    setNavigator((prev) => ({
      ...prev,
      searchCriteria: convetToSearchField(searchFields, value),
    }));
    init({
      ...navigator,
      searchCriteria: convetToSearchField(searchFields, value),
    });
  }

  return (
    <div className="Patients__main-container">
      <div className="p-4 mt-4 mb-4 Patients__date-container">
        <DateSelect
          show={show}
          setShow={setShow}
          patientDashboard={patientDashboard}
          selectOption={selectOption}
          setSelectOption={setSelectOption}
        />
        <div className="Patients__card-container">
          <ul className="Patients__card-holder">
            <li>
              <div className="card-left-sec">
                <p className="card-title">Visits</p>
                <p className="card-info">
                  <span className="total-count">
                    {dashoardDetails("visits")}
                  </span>
                  {/* <span className="total-progress up ">(+29%)</span> */}
                </p>
                {/* <p className="card-sub-info">Total today</p> */}
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-one">
                  <img src={UsersImg} alt="" />
                </span>
              </div>
            </li>

            <li>
              <div className="card-left-sec">
                <p className="card-title">Scheduled Surgeries</p>
                <p className="card-info">
                  <span className="total-count">
                    {dashoardDetails("scheduled")}
                  </span>
                  {/* <span className="total-progress up hide">(+18%)</span> */}
                </p>
                {/* <p className="card-sub-info">This week</p> */}
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-two">
                  <img src={User2Img} alt="" />
                </span>
              </div>
            </li>
            <li>
              <div className="card-left-sec">
                <p className="card-title">Arrived Patients</p>
                <p className="card-info">
                  <span className="total-count">
                    {dashoardDetails("arrived")}
                  </span>
                  {/* <span className="total-progress down ">(-14%)</span> */}
                </p>
                {/* <p className="card-sub-info">This week</p> */}
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-three">
                  <img src={User3Img} alt="" />
                </span>
              </div>
            </li>
            <li>
              <div className="card-left-sec">
                <p className="card-title">Missed Surgeries</p>
                <p className="card-info">
                  <span className="total-count">
                    {dashoardDetails("missed")}
                  </span>
                  {/* <span className="total-progress up ">(+42%)</span> */}
                </p>
                {/* <p className="card-sub-info">This week</p> */}
              </div>
              <div className="card-right-sec">
                <span className="card-icon bg-four">
                  <img src={User4Img} alt="" />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <DataGrid
        header={
          <DataGridHeader
            handleClick={handleClick}
            isSearchEnabled={true}
            isCalenderEnabled={false}
            isButtonEnabledLeft={false}
            isButtonEnabledRight={true}
            searchPlaceHolder={"Search Patients"}
            currentPageHeader={"Patient List"}
            onChange={handleSeachChange}
          />
        }
        onSortClick={onSortClick}
        onRowCountChange={onRowCountChange}
        onRowClick={onRowClick}
        onPageChange={onPageChange}
        sortable={true}
        navigator={navigator}
        column={products}
        value={values}
        loading={loading}
        optionsView={[
          {
            items: [
              {
                label: "Add visit",
                icon: "pi pi-pencil",
                template: itemRenderer,
              },
              {
                label: "Edit",
                icon: "pi pi-upload",
                template: itemRenderer,
              },
              {
                label: "Remove",
                icon: "pi pi-upload",
                template: itemRenderer,
              },
            ],
          },
        ]}
        isAccordionEnabled={false}
        isPaginatorEnabled={true}
      />
      <PrimarySideBar
        visible={visible}
        onHide={() => setVisible(false)}
        header={<div className="sideBar-header">Add Patient</div>}
      >
        <InputIterator
          control={control}
          errors={errors}
          formRender={formRender}
        />
        <div className="pt-3">
          <AddButtonSection disabled={serverLoading} buttons={buttons} />
        </div>
      </PrimarySideBar>
      <DevTool control={control} />
      {optionalVisible?.deletePatient && (
        <DeletePatient
          visible={optionalVisible?.deletePatient}
          patient={optionalVisible?.selectedPatient}
          patientPage
          setVisible={(bool, final) => {
            setOptionalVisible((prev) => ({ ...prev, deletePatient: bool }));
            if (final) {
              setLoading(true);
              init(navigator);
            }
          }}
        />
      )}
      {optionalVisible?.editPatient && (
        <AddOrEditPatient
          patient={[]}
          profile="patient"
          visible={optionalVisible?.editPatient}
          id={optionalVisible?.selectedPatient}
          setVisible={(bool, refresh) => {
            setOptionalVisible((prev) => ({ ...prev, editPatient: bool }));
            if (refresh) init(navigator);
          }}
        />
      )}
      {optionalVisible?.addVisit && (
        <AddVisits
          patientId={optionalVisible?.selectedPatient}
          visible={optionalVisible?.addVisit}
          setVisible={(bool) =>
            setOptionalVisible((prev) => ({ ...prev, addVisit: bool }))
          }
        />
      )}
    </div>
  );
};

export default Patients;
