import React, { useEffect } from "react";
import Search from "../../components/Search";
import "../../assets/styles//Logistics.scss";
import User2Img from "../../assets/images/user-2.svg";
import User3Img from "../../assets/images/user-3.svg";
import User4Img from "../../assets/images/user-4.svg";
import { TabView, TabPanel } from "primereact/tabview";
import ActionLogistic from "./actionLogistic";
import { commonApi, commonGetApi } from "../../utils/apiService";
import { useToast } from "../../commonComponents/toast";
import _ from "lodash";
import moment from "moment";
import DateSelection from "../../components/dateSelection";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { dashoardDetails } from "../../utils/utils";

const Logistics = () => {
  const { showToast } = useToast();
  const [refresh, setRefresh] = React.useState(false);
  const [dateVisible, setDateVisible] = React.useState(false);
  const [checkedData, setCheckedData] = React.useState([]);
  const [cardData, setCardData] = React.useState([]);
  const [date, setDate] = React.useState(new Date());

  const ActionApi = (api, payload, msg) => {
    commonApi(api, payload)
      .then((data) => {
        if (data?.data) {
          setRefresh(!refresh);
          showToast("success", msg);
          init();
        }
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
      });
  };

  const init = () => {
    commonGetApi("api/patienttransportationdashboard").then((data) => {
      data?.data?.data && setCardData(data?.data?.data);
    });
  };

  const handleAction = (event, data) => {
    if (_.isEmpty(data)) {
      showToast("info", "Please select records");
      return;
    }
    if (event == "Arrived" || event == "missed") {
      ActionApi(
        "/api/editPatientTransportationStatus",
        {
          patient_transportation_details_ids: data?.map(
            (item) => item?.patient_transportation_details_id
          ),
          status: event == "missed" ? "missed" : "arrived",
        },
        "Status updated successfully"
      );
    } else if (event == "Schedule") {
      setDateVisible(true);
      setCheckedData(
        data?.map((item) => ({
          patient_transportation_details_id:
            item?.patient_transportation_details_id,
          patient_visit_id: item?.patient_visit_id,
        }))
      );
    }
  };

  const confirm = () => {
    confirmDialog({
      message: "Do you want to make a follow up?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      acceptClassName: "accept",
      accept: followUp,
    });
  };

  const buttons = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      onClick: () => {
        if (date) {
          ActionApi(
            "/api/scheduleNewAppointment",
            {
              appointment_date: moment(date).format("YYYY-MM-DD"),
              patient_data: checkedData,
            },
            "Transportation scheduled successfully"
          );
          setCheckedData([]);
          setDateVisible(false);
        }
      },
    },
    {
      type: "primaryCancelButton",
      label: "Cancel",
      class: "col-4",
      onClick: () => {
        setCheckedData([]);
        setDateVisible(false);
      },
    },
  ];

  const followUp = (props) => {
    commonApi("/api/followup", {
      patient_transportation_details_id:
        props?.props?.itemProp?.patient_transportation_details_id,
    })
      .then((data) => {
        if (data?.response?.data?.message)
          return showToast("error", data?.response?.data?.message);
        showToast("success", "Follow up added successfully");
        setRefresh(!refresh);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
      });
  };

  const itemRender = (data, props) => {
    return (
      <div className="p-menuitem-content">
        <Button
          className="card-button-add"
          icon="pi pi-plus"
          label="Follow Up"
          onClick={() => {
            confirm();
            // setActionPopUp(props);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="Logistics__main-container">
      {/* <div className="Logistics__search-container">
        <Search />
      </div> */}
      <div className="Logistics__card-container">
        <ul className="Logistics__card-holder">
          <li>
            <div className="card-left-sec">
              <p className="card-title">Scheduled</p>
              <p className="card-info">
                <span className="total-count">
                  {dashoardDetails("scheduled", cardData) || 0}
                </span>
                {/* <span className="total-progress up">(+18%)</span> */}
              </p>
              <p className="card-sub-info">Last week analytics</p>
            </div>
            <div className="card-right-sec">
              <span className="card-icon bg-two">
                <img src={User2Img} alt="" />
              </span>
            </div>
          </li>
          <li>
            <div className="card-left-sec">
              <p className="card-title">Arrived</p>
              <p className="card-info">
                <span className="total-count">
                  {dashoardDetails("arrived", cardData) || 0}
                </span>
                {/* <span className="total-progress down">(-14%)</span> */}
              </p>
              <p className="card-sub-info">Last week analytics</p>
            </div>
            <div className="card-right-sec">
              <span className="card-icon bg-three">
                <img src={User3Img} alt="" />
              </span>
            </div>
          </li>
          <li>
            <div className="card-left-sec">
              <p className="card-title">Missed</p>
              <p className="card-info">
                <span className="total-count">
                  {dashoardDetails("missed", cardData) || 0}
                </span>
                {/* <span className="total-progress up">(+42%)</span> */}
              </p>
              <p className="card-sub-info">Last week analytics</p>
            </div>
            <div className="card-right-sec">
              <span className="card-icon bg-four">
                <img src={User4Img} alt="" />
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div className="Logistics__table-container">
        <div className="table-header-sec">Patient List</div>
        <div className="tab-view-sec">
          <TabView>
            <TabPanel header="Incoming Appointments">
              <ActionLogistic
                api={"api/patienttransportationdetails"}
                searchOption={"scheduled"}
                selectable={true}
                current
                refresh={refresh}
                isButtonEnabledLeft
                handSecoundaryleClick={(data) => handleAction("missed", data)}
                dataKey={"patient_transportation_details_id"}
                handleAction={handleAction}
              />
            </TabPanel>
            <TabPanel header="Arrived Appointments">
              <ActionLogistic
                api={"api/patienttransportationdetails"}
                searchOption={"arrived"}
                refresh={refresh}
              />
            </TabPanel>
            <TabPanel header="Missed Appointments">
              <ActionLogistic
                api={"api/patienttransportationdetails"}
                searchOption={"missed"}
                selectable={true}
                isButtonEnabledLeft
                currentPageHeader={"Schedule"}
                refresh={refresh}
                columnArray={["no_of_followup_calls"]}
                optionsView={[
                  {
                    items: [
                      {
                        label: "Add visit",
                        icon: "pi pi-pencil",
                        template: itemRender,
                      },
                    ],
                  },
                ]}
                handleAction={handleAction}
              />
            </TabPanel>
          </TabView>
        </div>
        {dateVisible && (
          <DateSelection
            visible={dateVisible}
            onHide={() => {
              setCheckedData([]);
              setDateVisible(false);
            }}
            date={date}
            setDate={setDate}
            minDate={new Date()}
            buttons={buttons}
          />
        )}
      </div>
      <ConfirmDialog />
    </div>
  );
};
export default Logistics;
