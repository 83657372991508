import React, { useState, useEffect } from "react";
import AddButtonSection from "../../components/AddButtonSection";
import { useForm } from "react-hook-form";
import {
  renderInputHeader,
  pattern,
  commonData,
  fetchOptions,
  addLookUpOption,
} from "../../utils/utils";
import { commonApi } from "../../utils/apiService";
import { useToast } from "../../commonComponents/toast";
import { DevTool } from "@hookform/devtools";
export default function ClinicalInformation({ patientVisitId }) {
  const {
    control,
    formState: { errors, dirtyFields },
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm();
  let headers = {
    "Content-Type": "application/json",
  };
  const { showToast } = useToast();
  const bloodPressure = watch("blood_pressure");
  const weight = watch("weight");
  const height = watch("height");
  const [isBackspace, setIsBackspace] = useState(false);
  const [isAlphabatic, setIsAlphabatic] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [ophthalmicHistory, setOphthalmicHistory] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [familyHistory, setfamilyHistory] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const lookupTypes = [
    { type: "Medical History", setter: setMedicalHistory },
    { type: "Allergies", setter: setAllergies },
    { type: "Ophthalmic History", setter: setOphthalmicHistory },
    { type: "Family History", setter: setfamilyHistory },
  ];
  const totalForm = [
    {
      type: "input-group",
      parentType: "plain",
      header: "Vitals",
      customClass: "w-full pb-3",
      formRender: [
        {
          type: "text",
          name: "height",
          label: "Height",
          fieldLabel: "height",
          placeholder: "cm",
          inputParentClass: "w-full pr-3",
          maxLength:5
        },
        {
          type: "text",
          name: "weight",
          label: "Weight",
          fieldLabel: "weight",
          placeholder: "kg",
          inputParentClass: "w-full pr-3",
          maxLength:5
        },
        {
          type: "text",
          name: "blood_pressure",
          label: "Blood Pressure",
          fieldLabel: "blood_pressure",
          placeholder: "XXX/XXX",
          inputParentClass: "w-full pr-3",
          maxLength:7,
        },
        {
          type: "text",
          name: "pulse",
          label: "Pulse",
          fieldLabel: "pulse",
          inputParentClass: "w-full pr-3",
          maxLength:3,
          rules: {
            pattern: {
              value: pattern("number"),
              message: "*Accept only number",
            },
          },
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Ophthalmic History",
          customClass: "py-3",
          formRender: [
            {
              type: "multiselect",
              name: "ophthalmic_history",
              label: "Select",
              fieldLabel: "ophthalmic_history",
              placeholder: "Type here",
              value: commonData(ophthalmicHistory, watch("ophthalmic_history")),
              inputParentClass: "w-full pr-3",
              options: ophthalmicHistory,
              nestedInput: { ...register("_ophthalmic_history") },
              nestedOnClick: (a, b) => {
                if (dirtyFields?._ophthalmic_history) {
                  addLookUpOption(
                    {
                      lookup_type_name: "Ophthalmic History",
                      name: watch("_ophthalmic_history"),
                    },
                    () => {
                      fetchOptions(
                        "Ophthalmic History",
                        setOphthalmicHistory,
                        showToast
                      );
                    },
                    showToast
                  );
                  setValue("_ophthalmic_history", "");
                }
              },
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Allergies",
          customClass: "py-3",
          formRender: [
            {
              type: "multiselect",
              name: "allergies",
              label: "Select",
              fieldLabel: "allergies",
              placeholder: "Type here",
              options: allergies,
              value: commonData(allergies, watch("allergies")),
              inputParentClass: "w-full pr-3",
              nestedInput: { ...register("_allergies") },
              nestedOnClick: () => {
                if (dirtyFields?._allergies) {
                  addLookUpOption(
                    {
                      lookup_type_name: "Allergies",
                      name: watch("_allergies"),
                    },
                    () => {
                      fetchOptions("Allergies", setAllergies, showToast);
                    },
                    showToast
                  );
                  setValue("_allergies", "");
                }
              },
            },
          ],
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Medical History",
          customClass: "py-3",
          formRender: [
            {
              type: "multiselect",
              name: "medical_history",
              label: "Select",
              fieldLabel: "medical_history",
              placeholder: "Type here",
              options: medicalHistory,
              value: commonData(medicalHistory, watch("medical_history")),
              inputParentClass: "w-full pr-3",
              nestedInput: { ...register("_medical_history") },
              nestedOnClick: () => {
                if (dirtyFields?._medical_history) {
                  addLookUpOption(
                    {
                      lookup_type_name: "Medical History",
                      name: watch("_medical_history"),
                    },
                    () => {
                      fetchOptions(
                        "Medical History",
                        setMedicalHistory,
                        showToast
                      );
                    },
                    showToast
                  );
                  setValue("_medical_history", "");
                }
              },
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Family History",
          customClass: "py-3",
          formRender: [
            {
              type: "multiselect",
              name: "family_history",
              label: "Select",
              fieldLabel: "family_history",
              placeholder: "Type here",
              options: familyHistory,
              value: commonData(familyHistory, watch("family_history")),
              inputParentClass: "w-full pr-3",
              nestedInput: { ...register("_family_history") },
              nestedOnClick: () => {
                if (dirtyFields?._family_history) {
                  addLookUpOption(
                    {
                      lookup_type_name: "Family History",
                      name: watch("_family_history"),
                    },
                    () => {
                      fetchOptions(
                        "Family History",
                        setfamilyHistory,
                        showToast
                      );
                    },
                    showToast
                  );
                  setValue("_family_history", "");
                }
              },
            },
          ],
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Procedures",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "procedures",
              label: "Comments",
              fieldLabel: "procedures",
              // placeholder: "Type to search",
              inputParentClass: "w-full pr-3",
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Immunizations",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "immunizations",
              label: "Comments",
              fieldLabel: "immunizations",
              // placeholder: "Type to search",
              inputParentClass: "w-full pr-3",
            },
          ],
        },
      ],
    },
    {
      parentType: "wrap",
      wrap: [
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Medications",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "medications",
              label: "Comments",
              fieldLabel: "medications",
              // placeholder: "Type to search",
              inputParentClass: "w-full pr-3",
            },
          ],
        },
        {
          type: "input-group",
          parentType: "wrap-with-flex",
          header: "Additional Notes",
          customClass: "py-3",
          formRender: [
            {
              type: "text",
              name: "additional_notes",
              label: "Comments",
              fieldLabel: "additional_notes",
              // placeholder: "Type to search",
              inputParentClass: "w-full pr-3",
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        setIsBackspace(true);
      }
      else if(!(/^[0-9]$/.test(event.key))){
        setIsAlphabatic(true)
      } else {
        setIsBackspace(false);
        setIsAlphabatic(false)
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onSubmit = async (data) => {
    if(loading)return;
    setLoading(true)
    const clinicalInformation = {
      patient_visit_id: patientVisitId,
      ...data,
    };
    const action = edit
      ? "editclinicalinformationdetails"
      : "addclinicalinformationdetails";
    await commonApi(`api/${action}`, clinicalInformation, {
      headers,
    }).then((data) => {
      if (data?.data) {
        showToast(
          "success",
          `${edit ? "Updated" : "Added"} Clinical Recommendation`
        );
        getClinicalInformation();
      } else {
        showToast("error", "Something went wrong");
      }
      setLoading(false)
    });
  };
  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        discard();
      },
    },
  ];
  const discard = () => {
    reset({
      pulse: "",
      blood_pressure: "",
      weight: "",
      height: "",
      medications: "",
      procedures: "",
      immunizations: "",
      additional_notes: "",
    });
  };
  const fetchOptions = async (lookupTypeName, setOptions) => {
    const data = { lookup_type_name: lookupTypeName };
    try {
      const response = await commonApi("api/searchlookupdetails", data, {
        headers,
      });
      const optionsData = response?.data?.data || [];
      const formattedOptions = optionsData.map((item) => ({
        label: item.name,
        value: item.lookup_id,
      }));
      setOptions(formattedOptions);
    } catch (err) {
      showToast("error", "Something went wrong");
    }
  };
  useEffect(() => {
    if (bloodPressure) {
      if (
        bloodPressure.length === 3 &&
        !isBackspace &&
        !bloodPressure.includes("/")
      ) {
        setValue("blood_pressure", bloodPressure + "/");
      }
      if (bloodPressure.length > 3 && !bloodPressure.includes("/")) {
        const firstPart = bloodPressure.slice(0, 3);
        const secondPart = bloodPressure.slice(3);
        setValue("blood_pressure", firstPart + "/" + secondPart);
      }
    }
    lookupTypes.forEach(({ type, setter }) => {
      fetchOptions(type, setter);
    });
  }, [bloodPressure, setValue]);

  useEffect(() => {
    if(isBackspace){
      setValue("weight", "");
    }
    else if(isAlphabatic){
      const alphabet = weight.replace(/\D/g, "");
      setValue("weight", weight ? alphabet + "kg" : "");
    }
    else{
      const sanitizedWeight = weight?.replace("kg", "");
      setValue("weight", weight ? sanitizedWeight + "kg" : "");
    }
  }, [weight]);

  useEffect(() => {
    if(isBackspace){
      setValue("height", "");
    }
    else if(isAlphabatic){
      const alphabet = height.replace(/\D/g, "");
      setValue("height", height ? alphabet + "cm" : "");
    }
    else{
      const sanitizedheight = height?.replace("cm", "");
      setValue("height", height ? sanitizedheight + "cm" : "");
    }
  }, [height]);

  useEffect(() => {
    getClinicalInformation();
  }, [patientVisitId]);

  const toReturnValue = (data) => {
    return data?.map((item) => item?.value) || null;
  };
  const getClinicalInformation = async () => {
    const data = { patient_visit_id: patientVisitId };
    try {
      const response = await commonApi(
        "api/searchclinicalinformationdetails",
        data,
        { headers }
      );
      const formData = response?.data?.data || null;
      if (Object.keys(formData).length !== 0) {
        setEdit(true);
        setValue("pulse", formData?.pulse || "");
        setValue("height", formData?.height || "");
        setValue("weight", formData?.weight || "");
        setValue("blood_pressure", formData?.blood_pressure || "");
        setValue("procedures", formData?.procedures || "");
        setValue("immunizations", formData?.immunizations || "");
        setValue("medications", formData?.medications || "");
        setValue("additional_notes", formData?.additional_notes || "");
        setValue("allergies", toReturnValue(formData?.allergies) || null);
        setValue(
          "medical_history",
          toReturnValue(formData?.medical_history) || null
        );
        setValue(
          "ophthalmic_history",
          toReturnValue(formData?.ophthalmic_history) || null
        );
        setValue(
          "family_history",
          toReturnValue(formData?.family_history) || null
        );
      } else if (Object.keys(formData).length === 0) {
        discard();
      }
    } catch (err) {
      showToast("error", "Something went wrong");
    }
  };

  return (
    <div>
      {totalForm?.map((item, index) => {
        return renderInputHeader(item, index, setValue, watch, control, errors);
      })}
      <div className="mt-6 ml-2">
        <AddButtonSection disabled={Object.keys(dirtyFields).length === 0 || loading} buttons={submitButtons} />
      </div>
      <DevTool control={control}></DevTool>
    </div>
  );
}
