import React, { useEffect, useState } from "react";
import PrimarySideBar from "../../components/sideBar/primarySideBar";
import InputIterator from "../../components/inputIterator";
import AddButtonSection from "../../components/AddButtonSection";
import { DevTool } from "@hookform/devtools";
import { useForm } from "react-hook-form";
import { addVisits } from "../../utils/serviceData";
import { commonApi } from "../../utils/apiService";
import { useToast } from "../../commonComponents/toast";
import Loader from "../../commonComponents/loader";
import { dateFormat } from "../../utils/utils";
export default function AddVisits({ patientId, ...props }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const [visible, setVisible] = useState(false);
  const [renderInput, setRenderInput] = useState({ formRender: addVisits });
  const [serverLoading, setServerLoading] = useState(null);
  const { showToast } = useToast();
  const init = async () => {
    try {
      let userQuery = {
        query: {
          page: 1,
          limit: 1000,
        },
      };
      const data = await commonApi("/api/userdetails", userQuery);
      const user = data?.data?.data;
      setRenderInput((prev) => ({
        ...prev,
        formRender: prev?.formRender?.map((field) => {
          if (field?.name === "examined_by") {
            return {
              ...field,
              options: user?.map((user) => ({
                label: user?.user_name,
                value: user?.user_id,
              })),
            };
          }
          return field;
        }),
        formName: "editProfile",
        buttons: addVisitsButton,
        header: <div className="sideBar-header">Add Visit</div>,
      }));
      const lookupData = {
        lookup_type_name: "VISIT_PURPOSE",
      };
      const purposeDetails = await commonApi(
        "/api/searchlookupdetails",
        lookupData
      );
      const purposes = purposeDetails?.data?.data;
      setRenderInput((prev) => ({
        ...prev,
        formRender: prev?.formRender?.map((field) => {
          if (field?.name === "purpose") {
            return {
              ...field,
              options: purposes?.map((purpose) => ({
                label: purpose?.name,
                value: purpose?.lookup_id,
              })),
            };
          }
          return field;
        }),
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onSubmit = async (data) => {
    if (serverLoading) return;
    setServerLoading(true);
    const visitdate = dateFormat(data["date"], "YYYY-MM-DD HH:mm:ss");
    let addVisitDetails = {
      patient_id: patientId,
      visit_date: visitdate,
      purpose: data?.purpose,
      examined_by: data?.examined_by,
    };
    await commonApi("/api/addpatientvisitdetails", addVisitDetails).then(
      (data) => {
        if (data?.statusText === "Created") {
          reset();
          showToast("success", "Patient visit details Added Successfully");
          props?.setVisible?.(false);
          setServerLoading(false);
        } else {
          showToast("error", "Something went wrong");
        }
        setServerLoading(false);
      }
    );
  };

  const addVisitsButton = [
    {
      type: "primarySuccessButton",
      label: "Submit",
      class: "col-4",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "primaryCancelButton",
      label: "Discard",
      class: "col-4",
      onClick: () => {
        props?.setVisible?.(false);
      },
    },
  ];

  useEffect(() => {
    init();
    setValue("date", new Date());
  }, []);
  useEffect(() => {
    setVisible(props?.visible);
  }, [props?.visible]);

  return (
    <React.Fragment>
      <PrimarySideBar
        visible={visible}
        onHide={() => props?.setVisible?.(false)}
        header={renderInput?.header}
      >
        {renderInput?.buttons ? (
          <React.Fragment>
            <InputIterator
              control={control}
              errors={errors}
              formRender={renderInput?.formRender}
            />

            <div className="pt-3">
              <AddButtonSection disabled={serverLoading} buttons={renderInput?.buttons} />
            </div>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </PrimarySideBar>
      <DevTool control={control} />
    </React.Fragment>
  );
}
