import React, { useEffect, useState } from "react";
import StepsComponent from "../../components/steps";
import { addDays, dateFormat, renderInputHeader } from "../../utils/utils";
import { useForm } from "react-hook-form";
import InputComponent from "../../commonComponents/inputComponent";
import { DevTool } from "@hookform/devtools";
import AddButtonSection from "../../components/AddButtonSection";
import InputPrimaryComponents from "../../components/inputPrimaryComponents";
import moment from "moment";
import { useToast } from "../../commonComponents/toast";
import { commonApi } from "../../utils/apiService";
import _ from "lodash";

export default function PostSurgeryFollowUp({ patientVisitId }) {
  const {
    control,
    formState: { errors,dirtyFields },
    setValue,
    watch,
    reset,
    handleSubmit,
  } = useForm();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [dataExist, setDataExist] = React.useState(false);
  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    [
      "date_of_surgery",
      "seventh_day_follow_up",
      "fourteenth_day_follow_up",
      "fortyth_day_follow_up",
    ].forEach((item) =>
      data[item] ? (data[item] = dateFormat(data[item])) : data[item]
    );
    if (data?.diet_given) {
      data.diet_given = data?.diet_given === "yes" ? true : false;
    }
    if (data?.medication_given) {
      data.medication_given = data?.medication_given === "yes" ? true : false;
    }
    data.patient_visit_id = patientVisitId;
    const finalData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value) finalData[key] = value;
    });
    await commonApi(
      dataExist
        ? "api/editPostSurgeryFollowUpDetails"
        : "api/addPostSurgeryFollowUpDetails",
      finalData
    )
      .then((data) => {
        if (data?.data) {
          showToast(
            "success",
            dataExist
              ? "Post Surgery Follow Up updated Successfully"
              : "Post Surgery Follow Up Added Successfully"
          );
          reset({
            date_of_surgery: "",
            surgery_details: "",
            medicine_details: "",
            seventh_day_follow_up: "",
            fourteenth_day_follow_up: "",
            fourteenth_comments: "",
            fortyth_day_follow_up: "",
            fortyth_day_comments: "",
            fortyth_date: "",
            seventh_day_comments: "",
            final_status_comments: "",
            medication: "",
            diet: "",
          });
          init();
          setLoading(false);
        }
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
        setLoading(false);
      });
  };

  const surgery = () =>
    [
      {
        parentType: "wrap-plain",
        grandClass: "flex show-dots",
        wrap: [
          {
            type: "input-group",
            customClass: "py-3 p-0",
            parentType: "wrap-plain",
            grandClass: "col-20 p-0",
            formRender: [
              {
                type: "calendar",
                name: "date_of_surgery",
                label: "Select Date",
                fieldLabel: "date_of_surgery",
                placeholder: "DD-MM-YYYY",
                inputParentClass: "w-full pr-3 div_pb-0",
                rules: {
                  required: "Surgery Date is required",
                },
              },
            ],
          },
          {
            type: "input-group",
            parentType: "wrap-plain",
            customClass: "py-3",
            grandClass: "col-40 p-0",
            formRender: [
              {
                type: "text",
                name: "surgery_details",
                label: "Surgery Details",
                fieldLabel: "surgery_details",
                // placeholder: "Surgery Details",
                inputParentClass: "w-full pr-3 div_pb-0",
              },
            ],
          },
          {
            type: "input-group",
            parentType: "wrap-plain",
            customClass: "py-3",
            grandClass: "col-40 p-0",
            formRender: [
              {
                type: "text",
                name: "medicine_details",
                label: "Medicine Details",
                fieldLabel: "medicine_details",
                // placeholder: "Select Date",
                inputParentClass: "w-full pr-3 div_pb-0",
              },
            ],
          },
        ],
      },
    ].map((item, index) =>
      renderInputHeader(item, index, setValue, watch, control, errors)
    );

  const init = () => {
    if (patientVisitId) {
      commonApi("/api/searchPostSurgeryFollowUpDetails", {
        patient_visit_id: patientVisitId,
      }).then((data) => {
        if (data?.data?.data && !_.isEmpty(data?.data?.data)) {
          setDataExist(true);
          Object.keys(data?.data?.data).forEach((key) => {
            if (["diet_given", "medication_given"].includes(key))
              setValue(key, data?.data?.data[key] === true ? "yes" : "no");
            else if (data?.data?.data[key]?.split("T")?.[1] === "00:00:00.000Z")
              setValue(key, new Date(data?.data?.data[key]));
            else setValue(key, data?.data?.data[key]);
          });
        }
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const seventhFollowUp = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"calendar"}
                control={control}
                errors={errors}
                name={"seventh_day_follow_up"}
                label={"Select Date"}
                placeholder={ "DD-MM-YYYY"}
                rules={{
                  required: "Surgery Date is required",
                }}
                minDate={
                  watch("date_of_surgery") && addDays(watch("surgery_date"), 1)
                }
                disabled={watch("date_of_surgery") ? false : true}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-80 p-0">
        <div className="card flex justify-content-center py-3">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"text"}
                control={control}
                errors={errors}
                name={"seventh_day_comments"}
                label={"Comments"}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const fortynthFollowUp = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"calendar"}
                control={control}
                errors={errors}
                placeholder={ "DD-MM-YYYY"}
                name={"fourteenth_day_follow_up"}
                label={"Select Date"}
                disabled={watch("seventh_day_follow_up") ? false : true}
                rules={{
                  required: "Surgery Date is required",
                }}
                minDate={
                  watch("seventh_day_follow_up") &&
                  addDays(watch("seventh_day_follow_up"), 1)
                }
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-80 p-0">
        <div className="card flex justify-content-center py-3">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"text"}
                control={control}
                errors={errors}
                name={"fourteenth_comments"}
                label={"Comments"}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const fortythFollowUp = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"calendar"}
                placeholder={ "DD-MM-YYYY"}
                control={control}
                errors={errors}
                name={"fortyth_day_follow_up"}
                label={"Select Date"}
                minDate={
                  watch("fourteenth_day_follow_up") &&
                  addDays(watch("fourteenth_day_follow_up"), 1)
                }
                disabled={watch("fourteenth_day_follow_up") ? false : true}
                rules={{
                  required: "Surgery Date is required",
                }}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-80 p-0">
        <div className="card flex justify-content-center py-3">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"text"}
                control={control}
                errors={errors}
                name={"fortyth_day_comments"}
                label={"Comments"}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const finalStatus = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"dropdown"}
                control={control}
                errors={errors}
                placeholder={ "Select"}
                name={"fortyth_date"}
                label={"Final Status"}
                // rules={item.rules}
                // value={item?.value}
                disabled={
                  watch("fortyth_day_follow_up")
                    ? moment(watch("fortyth_day_follow_up")).isBefore(
                        moment(),
                        "day"
                      )
                    : true
                }
                options={[
                  {
                    label: "Successful",
                    value: "successful",
                  },
                  {
                    label: "UnSuccessful",
                    value: "unsuccessful",
                  },
                ]}
                // infoText={item.infoText}
                // required={item.required}
                // autoFocus={item?.autoFocus}
                // placeholder={item?.placeholder}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-80 p-0">
        <div className="card flex justify-content-center py-3">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
              <InputPrimaryComponents
                type={"text"}
                control={control}
                errors={errors}
                name={"final_status_comments"}
                label={"Comments"}
                inputParentClass={"w-full pr-3 div_pb-0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const MedicationGiven = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
            <InputComponent
              type={"radio"}
              name={"medication_given"}
              control={control}
              radioMenu={[
                {
                  inputId: "yes",
                  name: "medication_given",
                  label: "Yes",
                },
                {
                  inputId: "no",
                  name: "medication_given",
                  label: "No",
                },
              ]}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const DietGiven = () => (
    <div className="flex show-dots">
      <div className="col-20 p-0">
        <div className="card flex justify-content-center py-3 p-0">
          <div className="w-full">
            <div className="flex w-full placeHolder-primaryBlack">
            <InputComponent
              type={"radio"}
              name={"diet_given"}
              control={control}
              radioMenu={[
                {
                  inputId: "yes",
                  name: "diet_given",
                  label: "Yes",
                },
                {
                  inputId: "no",
                  name: "diet_given",
                  label: "No",
                },
              ]}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const submitButtons = [
    {
      type: "primarySuccessButton",
      label: "Save Changes",
      class: "w-11rem pr-3",
      onClick: handleSubmit(onSubmit),
    },
    {
      type: "secondaryButton",
      label: "Discard",
      class: "w-7rem",
      onClick: () => {
        // setVisible(false);
      },
    },
  ];

  return (
    <div>
      <div className="public_sans_500 text-lg text-secondaryTextColor pb-4">
        Post Surgery Follow-Up
      </div>
      <div>
        <StepsComponent
          current={2}
          vertical
          stepList={[
            {
              title: "Surgery",
              description: <div>{surgery()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "7th Day",
              description: <div>{seventhFollowUp()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "14th Day",
              description: <div>{fortynthFollowUp()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "40th Day",
              description: <div>{fortythFollowUp()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "Final Status",
              description: <div>{finalStatus()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "Medication Given",
              description: <div>{MedicationGiven()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "Diet Given",
              description: <div>{DietGiven()}</div>,
              // icon: "pi pi-fw pi-user",
            },
            {
              title: "",
              description: <div>{}</div>,
              // icon: "pi pi-fw pi-user",
            },
          ]}
        />
      </div>

      <div className="ml-2 pt-6">
        <AddButtonSection disabled={Object.keys(dirtyFields).length === 0 || loading} buttons={submitButtons} />
      </div>
      <DevTool control={control} />
    </div>
  );
}
